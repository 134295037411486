<template>
  <img
    v-if="srcLink"
    :alt="alt || image.alternativeText || altFallback"
    :class="imgCSS"
    :height="height"
    :width="width"
    :decoding="lazy ? 'async' : 'sync'"
    :loading="lazy ? 'lazy' : undefined"
    :src="srcLink"
    :srcset="srcSet"
  />
</template>

<script setup lang="ts">
import { calculateImageSize } from '~/helpers/imageRatio';
import type { StrapiImage, StrapiImageFormat } from '~/apollo/types/types';
import { getLargestImageURL, ImageFormat } from '~/helpers/strapiHelper';

const props = defineProps({
  image: {
    type: Object as PropType<StrapiImage>,
    required: true,
  },
  forceWidth: {
    type: Number,
    default: undefined,
  },
  forceHeight: {
    type: Number,
    default: undefined,
  },
  alt: {
    type: String,
    default: undefined,
  },
  altFallback: {
    type: String,
    default: undefined,
  },
  lazy: {
    type: Boolean,
    default: true,
  },
  autoScale: {
    type: Boolean,
    default: false,
  },
  imageClass: {
    type: String,
    default: undefined,
  },
  fluid: {
    type: Boolean,
    default: false,
  },
  /**
   * This class is used to show a background in a properly sized container with specified widths and heights or when the height is unknown.
   * object-fit: cover and positioning ensures that our image is placed correctly
   * within the container and not showing any empty space.
   * Can get into conflict with `fluid` prop, so use either one of them depending on the use case
   */
  asBackground: {
    type: Boolean,
    default: false,
  },
});

// additional image formats
const imageFormats = computed(() => {
  return (
    Array.from(props.image.formats || [])
      .filter((f) => f.format !== ImageFormat.THUMBNAIL)
      .sort((a, b) => a.width - b.width) || []
  );
});

const srcSet = computed((): string | null => {
  if (imageFormats.value.length === 0) {
    return null;
  }

  let set: string = ``;
  imageFormats.value.forEach((format: StrapiImageFormat) => {
    set += `${format.url} ${format.width}w, `;
  });

  set += `${props.image.url} ${props.image.width}w,`;

  return set.slice(0, -1);
});

const srcLink = ref<string>(getLargestImageURL(props.image));

useHead({
  link:
    props.lazy == false
      ? [
          {
            rel: 'preload',
            as: 'image',
            href: srcLink.value,
            imagesrcset: srcSet.value || undefined,
            fetchpriority: 'high',
          },
        ]
      : [],
});

const imgCSS = computed<Array<string>>(() => {
  const cssClasses = props.imageClass ? [props.imageClass] : [];

  if (props.fluid) {
    cssClasses.push('img-fluid');
  }

  if (props.asBackground) {
    cssClasses.push(
      'position-absolute w-100 h-100 top-0 start-0 object-cover z-[-1]',
    );
  }

  return cssClasses;
});

const width = computed<number>(() => {
  // Forced width
  if (props.forceWidth) {
    return props.forceWidth;
  }

  // If forced height, we need to calculate the new width
  if (props.forceHeight) {
    const sizes = calculateImageSize(
      props.image.width,
      props.image.height,
      undefined,
      props.forceHeight,
    );
    return sizes[0];
  }

  // Strapi value
  return props.image.width;
});

const height = computed<number>(() => {
  // Forced height
  if (props.forceHeight) {
    return props.forceHeight;
  }

  // If forced width, we need to calculate the new height
  if (props.forceWidth) {
    const sizes = calculateImageSize(
      props.image.width,
      props.image.height,
      props.forceWidth,
      undefined,
    );
    return sizes[1];
  }
  // Strapi value
  return props.image.height;
});
</script>
