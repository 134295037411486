import type { StrapiImage, StrapiImageFormat } from '~/apollo/types/types';

export function getSmallestImageURL(img: StrapiImage): string {
  if (img == null || img.url === undefined) {
    return null;
  }

  if (img.formats == null || img.formats.length === 0) {
    return img.url;
  }

  let smallestWidth: number | undefined;
  let smallestImage: StrapiImageFormat;
  for (const format of img.formats) {
    if (format.format !== 'thumbnail') {
      if (smallestWidth == null || format.width < smallestWidth) {
        smallestWidth = format.width;
        smallestImage = format;
      }
    }
  }

  if (smallestImage != null && smallestImage.url != null) {
    return smallestImage.url;
  }

  return img.url;
}

export function getLargestImageURL(img: StrapiImage): string {
  if (img == null || img.url === undefined) {
    return null;
  }

  if (img.formats == null || img.formats.length === 0) {
    return img.url;
  }

  return img.formats.sort((a, b) => b.width - a.width)[0].url;
}

// Image formats are defined in the Strapi backend
// But keep in mind the format string is just the prefix.
// The actual format can be different.
// Example format from GraphQL 'medium_webp'
export enum ImageFormat {
  THUMBNAIL = 'thumbnail', // @deprecated image width: 245px
  XSMALL = 'xsmall', // image width: 768px (min-width: 576px)
  SMALL = 'small', // image width: 1024px  (min-width: 768px)
  MEDIUM = 'medium', // image width: 1280px (min-width: 1024px)
  LARGE = 'large', // image width: 1440px (min-width: 1200px)
  XLARGE = 'xlarge', // image width: 1920px (min-width: 1440px)
}

export function findImageFormatURL(
  img: StrapiImage,
  format: ImageFormat,
): string {
  if (img == null || img.url === undefined) {
    return '';
  }

  if (img.formats == null || img.formats.length === 0) {
    return img.url;
  }

  for (const imageFormat of img.formats) {
    if (
      (imageFormat.format && imageFormat.format.split('_')[0] === format) ||
      imageFormat.url.toLowerCase().startsWith(format.toLowerCase())
    ) {
      return imageFormat.url;
    }
  }

  return getSmallestImageURL(img);
}
