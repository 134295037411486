// Return new image sizes
export function calculateImageSize(
  x: number,
  y: number,
  xMax?: number,
  yMax?: number,
): (number | undefined)[] {
  if (xMax && yMax) {
    // Maximum values of height and width given, aspect ratio preserved.
    if (y > x) {
      return [Math.round((yMax * x) / y), yMax];
    }
    return [xMax, Math.round((xMax * y) / x)];
  }
  if (xMax) {
    if (x === 0) {
      return [xMax, undefined];
    }
    // Width given, height automagically selected to preserve aspect ratio.
    return [xMax, Math.round((xMax * y) / x)];
  }
  if (yMax) {
    if (y === 0) {
      return [undefined, yMax];
    }
    // Height given, width automagically selected to preserve aspect ratio.
    return [Math.round((yMax * x) / y), yMax];
  }
  throw new Error('Missing xMax and/or yMax');
}
